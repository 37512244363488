import React, { useState } from 'react';
import '../styles/footer.css';
// Font awesomee
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    const [showFooter, setFooter] = useState(true);
    return <div className='App-footer-box'>
        <div className='App-footer-content'>
            <div className='App-footer-title'>Mission Statement &amp; Tax Information <span onClick={() => {setFooter(!showFooter)}} className='App-footer-minimize'><FontAwesomeIcon icon={showFooter ? faArrowDown : faArrowUp}/></span></div>
            <div className={`App-footer-body show-footer-${showFooter}`}>
            <strong>Angel Quest, Inc.</strong> is on a mission to help people improve overall health of mind, body, and soul by providing self help tools that can help them on their quest of self discovery. <i>A <strong>509(a)2</strong> public charity that qualifies as an exempt organization under section <strong>501(c)(3)</strong> of the Internal Revenue Code, or the corresponding section of any future federal tax code</i>.
            </div>
        </div>
</div>
};

export default Footer;