import React from 'react';
import '../styles/contact.css';

const Contact = () => {
  return <div className='Page-body'>
    <div className='Page-title'>Contact Us</div>
    <div className='Page-box-items'>
      <div className='Page-contact-box'>
        <div className='Box-title'>Please fill out the form</div>
        <div className='Box-summary Box-contact-form'>
          <div>
            <div className='form-header'><span>First Name</span></div>
            <div className='form-header'><span>Last Name</span></div>
          </div>
          <div>
            <div><input type={'text'}/></div>
            <div><input type={'text'}/></div>
          </div>
          <div>
            <div className='form-header'><span>Email</span></div>
          </div>
          <div>
            <div><input className='form-input-100' type={'text'}/></div>
          </div>
        </div>
        <div className='Box-buttons'><button className='Box-button'>Apply</button></div>
      </div>
    </div>
  </div>
};

export default Contact;