import React from 'react';

import '../styles/pages.css';
import '../styles/home.css';

const Home = () => {
  return <div className='Page-body'>
    <div className='Page-title'>Welcome to Angel Quest, Inc.</div>
    <div className='Page-body-row'>
      <div className=''>
        <p>
        Hi, my name is Pamela Brown, 
        </p>
        <p>
        As the founder of Angel Quest, Inc. I created this organization after struggling for years with depression, anger, and shame from a traumatic childhood. Although I have been trying to overcome this trauma since I was a teen, I couldn’t devote a lot of time and money to helping myself. Over the past ten years, I have been working hard on my quest to find happiness through self-love, acceptance, and forgiveness. Along the way, I found myself stuck numerous times. To make any progress, I would need outside help to find holistic balance (physically, mentally, emotionally, and spiritually). Sometimes it was a book, sometimes services (counseling, therapy, acupuncture, etc.), and sometimes a new skill (healthy habits, yoga, etc.) Three years ago, I decided to push myself further into self-discovery. I made great strides in freeing myself from the trauma and finding happiness, but at a great financial cost. When I realized that I could have found happiness a lot sooner if I only had the money, it inspired me to start Angel Quest, Inc. in the hope of helping others find happiness sooner. I believe everyone should be able to live their best life, and money should not be a barrier to anyone on a quest to seek happiness.
        </p>
        <p>
          At Angel Quest, Inc. we are committed to helping guide others on their quest for a balanced life by providing self-help tools and services for mental, emotional, physical, and spiritual health. Our services include information, instructions, self-help tutorials, outreach, and advocacy of holistic choices.
        </p>
        <p>
          We intend to bring value, inspire positive change, and encourage self-awareness, self-love, and happiness. Because when we are happy, we are kind to ourselves and others, and we bring joy to the world around us. We become living angels working together to find balance and peace on earth.
        </p>
        <p>
          Join us, become an angel among us, and help yourself and others live their best lives.
        </p>
      </div>
    </div>
  </div>
};

export default Home;