import React from 'react';

import '../styles/pages.css';
import '../styles/takeaction.css';

const TakeAction = () => {
    return <div className='Page-body'>
        <div className='Page-title'>Take Action</div>
        <div className='Page-box-items'>
          {/* <div>
            <div className='Box-title'>Volunteer</div>
            <div className='Box-image image-volunteer'></div>
            <div className='Box-summary'>Blah blah blah blah blah blah blah</div>
            <div className='Box-buttons'><button className='Box-button'>Apply</button></div>
          </div>
          <div>
            <div className='Box-title'>Benefactor</div>
            <div className='Box-image image-benefactor'></div>
            <div className='Box-summary'>Blah blah blah blah blah blah blah</div>
            <div className='Box-buttons'><button className='Box-button'>Apply</button></div>
          </div>
          <div>
            <div className='Box-title'>Practicioner</div>
            <div className='Box-image image-practicioner'></div>
            <div className='Box-summary'>Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. Blah blah blah blah blah blah blah. </div>
            <div className='Box-buttons'><button className='Box-button'>Apply</button></div>
          </div>
          <div>
            <div className='Box-title'>Partner</div>
            <div className='Box-image image-partner'></div>
            <div className='Box-summary'>Yoga instructors, life coaches, dieticians, naturopathic doctors, astrologers, all are welcomed! We want to partner and network with many professionals that want to help people, animals, and mother nature.</div>
            <div className='Box-buttons'><button className='Box-button'>Apply</button></div>
          </div> */}
        </div>
      </div>;
  };
  
  export default TakeAction;