import React from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './pages/Home';
import TakeAction from './pages/TakeAction';
import Podcasts from './pages/Podcasts';
import News from './pages/News';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import Account from './pages/Account';

// Font awesomee
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faContactBook, faDonate, faPodcast, faRadio, faRunning, faUser } from '@fortawesome/free-solid-svg-icons'

import './App.css';
import Donate from './pages/Donate';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    }
  }
  componentDidMount() {}
  render() {
    return (
      <div className="App">
        <div className='App-body'>

          <BrowserRouter>
            <header className="App-header">
              <div className='left'>
                <Link to="/"><div className='App-header-title'>Angel Quest, Inc.</div></Link>
              </div>
              <div className='middle'>
                <Link to="/"><div className='App-header-title'><FontAwesomeIcon icon={faRunning} /> Take Action</div></Link>
                <Link to="/"><div className='App-header-title'><FontAwesomeIcon icon={faRadio} /> News</div></Link>
                <Link to="/"><div className='App-header-title'><FontAwesomeIcon icon={faPodcast} /> Podcasts</div></Link>
                <Link to="/contact"><div className='App-header-title'><FontAwesomeIcon icon={faContactBook} /> Contact</div></Link>
                <Link to="/"><div className='App-header-title account-button'><FontAwesomeIcon icon={faUser} /> My Account</div></Link>
              </div>
              <div className='right'>
                <Link to="/"><div className='App-header-title donate-button'><FontAwesomeIcon icon={faDonate} /> Donate</div></Link>
              </div>
              <div className='menu'>
                <FontAwesomeIcon icon={faBars} onClick={() => this.setState({showMenu: !this.state.showMenu})} />
                <span className={`menu-overlay menu-overlay-${this.state.showMenu}`} onClick={() => this.setState({showMenu: false})}>
                  <span className={`menu-items menu-${this.state.showMenu}`}>
                    <Link onClick={() => this.setState({showMenu: false})} to="/"><div className='App-header-title menu-item'><FontAwesomeIcon icon={faRunning} /> Take Action</div></Link>
                    <Link onClick={() => this.setState({showMenu: false})} to="/"><div className='App-header-title menu-item'><FontAwesomeIcon icon={faRadio} /> News</div></Link>
                    <Link onClick={() => this.setState({showMenu: false})} to="/"><div className='App-header-title menu-item'><FontAwesomeIcon icon={faPodcast} /> Podcasts</div></Link>
                    <Link onClick={() => this.setState({showMenu: false})} to="/contact"><div className='App-header-title menu-item'><FontAwesomeIcon icon={faContactBook} /> Contact</div></Link>
                    <Link onClick={() => this.setState({showMenu: false})} to="/"><div className='App-header-title menu-item account-button'><FontAwesomeIcon icon={faUser} /> My Account</div></Link>
                    <Link onClick={() => this.setState({showMenu: false})} to="/"><div className='App-header-title menu-item'><FontAwesomeIcon icon={faDonate} /> Donate</div></Link>
                  </span>
                </span>
              </div>
            </header>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/take-action" element={<TakeAction />}></Route>
              <Route path="/news" element={<News />}></Route>
              <Route path="/podcasts" element={<Podcasts />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/account" element={<Account />}></Route>
              <Route path="/donate" element={<Donate />}></Route>
            </Routes>
            <Footer/>
          </BrowserRouter>
        </div>
      </div>
    )
  }
};

export default App;
